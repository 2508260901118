import React, { Component } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import InstaFeed from "../components/instaFeed";
import Helmet from "react-helmet";
import "./thankYou.scss";
import { isBrowser } from "react-device-detect";
const SHAREASALE_MERCHANT_ID = process.env.GATSBY_SHAREASALE_MERCHANT_ID;

class ThankYouPage extends Component {

  render () {

    return (

      <Layout isAlternate={true}>
        <SEO title="Thank you" />
        <Helmet>
          <script type="text/javascript">{`
            if(localStorage.getItem("orderId") !== null){
              var orderId = localStorage.getItem("orderId");
              var orderAmount = localStorage.getItem("orderAmount");
              _revoffers_track = window._revoffers_track||{};
              _revoffers_track.type = "thank_you";
              _revoffers_track.order_id = orderId;
              _revoffers_track.order_value = orderAmount;
              console.log("_revoffers_track", _revoffers_track);
            }
          `}</script>
          <script type="text/javascript" defer="defer">{`
            if(localStorage.getItem("orderId") !== null){
              var orderId = localStorage.getItem("orderId");
              var orderAmount = localStorage.getItem("orderAmount");
              var shareasaleAmount = orderAmount;
              if(localStorage.getItem("upsellOrderAmount") !== null){
                shareasaleAmount = (parseFloat(localStorage.getItem("upsellOrderAmount")) + parseFloat(shareasaleAmount)).toFixed(2);
                localStorage.removeItem("upsellOrderAmount");
              }
              localStorage.removeItem("orderId");
              localStorage.removeItem("orderAmount");
              console.log("orderId", orderId);
              var shareasaleShopifyUrl ="https://www.shareasale.com/sale.cfm?tracking="+orderId+"&amount="+shareasaleAmount+"&merchantID=${SHAREASALE_MERCHANT_ID}&transtype=sale";
              console.log("shareasaleShopifyUrl",shareasaleShopifyUrl);
              var shareasalePixelImg = new Image(); 
              shareasalePixelImg.src = shareasaleShopifyUrl;
              shareasalePixelImg.width = 1; 
              shareasalePixelImg.height = 1; 
              document.body.appendChild(shareasalePixelImg);
            }
      `}</script>
          <script src="https://www.dwin1.com/19038.js" type="text/javascript" defer="defer"></script>
        </Helmet>
        <section className="hero is-large1 is-fullheight has-yellow-background thank-you-section">

          <video className="thank-you-section-bg-video" autoPlay loop preload="auto" muted>
            {isBrowser ?
              <source src="/thank-you-video-bg-desktop.mp4" /> :
              <source src="/thank-you-video-bg-mobile.mp4" />
            }
          </video>

          <div className="hero-body">

            <div className="container">
              <div className="columns is-centered">
                <div className="column is-6 has-text-centered has-text-light">

                  <h1 className="has-text-light">
                    Thank you
                  </h1>
                  <p>Your new #reasontosmile is on the way.</p>
                </div>

              </div>
            </div>
          </div>

        </section>
        <section className="section is-medium">
          <InstaFeed />
        </section>
      </Layout>
    );
  }
}

export default ThankYouPage;
